import styles from "./RegisterForm.module.scss"
import React, { useEffect, useState } from "react"
import cx from "clsx"
import { motion } from "framer-motion"
import { useRouter } from "next/router"
import { useAtomValue, useSetAtom } from "jotai"
import { queryClientAtom } from "jotai/query"

import { fetchUser, registerAtom, userKeys } from "../../atoms/userAtom"

import { registerCandidateAtom } from "../../atoms/notificationAtom"

import { handleSignIn } from "./../LoginForm/LoginForm"

import PasswordField from "../PasswordField/PasswordField"
import EmailField from "../EmailField/EmailField"
import Button from "../Button/Button"
import Text from "../Text/Text"
import Link from "../Link/Link"
import { useSession } from "next-auth/react"

import axios from "axios"

const apiNodeFetcher = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SOCKET_HOST,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

const RegisterForm = ({ switchForm, redirectUrl, ...restProps }) => {
  const router = useRouter()

  const emitRegisterNotif = useSetAtom(registerCandidateAtom)

  const { data: session } = useSession()

  const register = useSetAtom(registerAtom)
  const queryClient = useAtomValue(queryClientAtom)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  const [hasEmailError, setHasEmailError] = useState(false)
  const [hasPasswordError, setHasPasswordError] = useState(false)

  useEffect(() => {
    if (session?.token && router && router?.isReady) {
      router.push("/dashboard/home")
    }
  }, [router, session])

  const handleLogin = async ({ email, password, candidateId, apiToken }) => {
    let { status, ok, error } = await handleSignIn({ email, password })

    if (error && ok && status === 200) {
      setLoading(false)
      return
    }

    if (error && !ok && status === 401) {
      setLoading(false)
      return
    }

    if (ok && !error && status === 200) {
      if (candidateId && apiToken) {
        // console.log({ emitting: candidateId })
        emitRegisterNotif({ candidateId, apiToken })
      }
      if (redirectUrl) {
        if (redirectUrl === "current") {
          router.replace({ pathname: router?.pathname, query: router?.query })
          return
        }
        router.replace(redirectUrl)
        return
      }
      router.replace("/dashboard/home")
      return
    }
  }

  const handleRegisterAccount = async (event) => {
    event.preventDefault()

    if (email && password) {
      setLoading(true)
      await register({
        user: { email, password, onboarded: false },
        onSuccess: handleLogin,
        onError: (error) => {
          setLoading(false)
        },
      })
    }
  }

  return (
    <motion.form
      initial={{ x: 1000 }}
      animate={{ x: 0 }}
      exit={{ x: -1000 }}
      transition={{ duration: 0.12, ease: "easeOut" }}
      className={styles.loginForm}
      onSubmit={handleRegisterAccount}
      {...restProps}
    >
      <EmailField
        label="Email"
        placeholder="misal, shaugi@gmail.com"
        id="user-register-email"
        value={email}
        setValue={setEmail}
        disabled={loading}
        setHasError={setHasEmailError}
        autoFocus
      />
      <PasswordField
        validate
        id="user-current-password"
        label="Password"
        value={password}
        setValue={(e) => setPassword(e.currentTarget.value)}
        disabled={loading}
        hint
        setHasError={setHasPasswordError}
      />
      <Text as="p" small className={styles.concern}>
        Dengan membuat akun Talent Indonesia, maka kamu telah menyetujui{" "}
        <Link className={styles.link} to="/kebijakan-privasi">
          Kebijakan Privasi
        </Link>{" "}
        dan{" "}
        <Link className={styles.link} to="/syarat-penggunaan">
          Syarat Penggunaan
        </Link>{" "}
        Talent Indonesia.
      </Text>
      <Button
        as="submit"
        primary
        large
        stretch
        disabled={
          !email || !password || loading || hasEmailError || hasPasswordError
        }
        loading={loading}
        className={styles.cta}
      >
        Buat akun
      </Button>
      <div className={styles.alternative}>
        <Text as="p" align="center" small>
          Sudah punya akun?{" "}
        </Text>
        {switchForm ? (
          <Button primaryText onClick={switchForm} small>
            Login
          </Button>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </div>
    </motion.form>
  )
}

export default RegisterForm
