import React from "react"

const SendFilledIcon = ({ style, title, className, ...restProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ display: "block", ...style }}
      viewBox="0 0 24 24"
      fill="none"
      {...restProps}
    >
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42484 21.3143L21.2603 13.1115C21.762 12.8808 21.9817 12.2869 21.7509 11.7852C21.6512 11.5683 21.4771 11.3942 21.2603 11.2945L3.42484 3.09172C2.92308 2.86095 2.32925 3.08064 2.09848 3.5824C2.03839 3.71304 2.00719 3.85511 2.007 3.99891L2 9.25304C1.99939 9.7118 2.31101 10.1121 2.75589 10.2241L10.6173 12.203L2.75589 14.1819C2.31101 14.2939 1.99939 14.6942 2 15.153L2.007 20.4071C2.00773 20.9594 2.45605 21.4065 3.00833 21.4058C3.15213 21.4056 3.29419 21.3744 3.42484 21.3143Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SendFilledIcon
