import styles from './FieldHint.module.scss'
import React, { Component } from 'react'
import clsx from 'clsx'

import Text from '../Text/Text'

class FieldHint extends Component {
  render() {
    const { tone, children, ...restProps } = this.props

    return (
      <Text
        small
        {...restProps}
        className={clsx({
          [styles.root]: true,
          [styles[tone]]: tone,
        })}
      >
        {children}
      </Text>
    )
  }
}

export default FieldHint
