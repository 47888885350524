import styles from './Textarea.module.scss'
import React, { useState } from 'react'
import cx from 'clsx'

import FieldHint from '../FieldHint/FieldHint'
import FieldArea from '../FieldArea/FieldArea'
import FieldLabel from '../FieldLabel/FieldLabel'

const Textarea = ({
  minRows,
  dark,
  id,
  name,
  type,
  value,
  setValue,
  defaultValue,

  className,
  labelClassName,
  fieldClassName,

  label,
  secondaryLabel,
  tertiaryLabel,

  message,
  setMessage,
  tone,
  setTone,

  onChange,
  onBlur,
  onFocus,
  yupShape,

  autoFocus,

  small,
  inline,

  placeholder,
  disabled,
  required,

  ...restProps
}) => {
  const [internalValue, setInternalValue] = useState('')
  const [internalTone, setInternalTone] = useState('')
  const [internalMessage, setInternalMessage] = useState('')

  return (
    <div
      className={cx({
        [styles.root]: true,
        [styles.inline]: inline,
        [className]: className,
      })}
      {...restProps}
    >
      {label && (
        <FieldLabel
          dark={dark}
          className={labelClassName}
          id={id}
          label={label}
          small={small}
          inline={inline}
          secondaryLabel={secondaryLabel}
          tertiaryLabel={tertiaryLabel}
        />
      )}
      <FieldArea
        dark={dark}
        id={id}
        name={name}
        type={type}
        className={fieldClassName}
        placeholder={placeholder}
        small={small}
        inline={inline}
        tone={tone ?? internalTone}
        setTone={setTone ?? setInternalTone}
        value={value ?? internalValue}
        setValue={setValue ? setValue : (e) => setInternalValue(e.target.value)}
        setMessage={setMessage ?? setInternalMessage}
        required={required ?? false}
        autoFocus={autoFocus}
        disabled={disabled}
        minRows={minRows}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {tone && message && <FieldHint tone={tone}>{message}</FieldHint>}
      {!tone && !message && internalTone && internalMessage && (
        <FieldHint tone={internalTone}>{internalMessage}</FieldHint>
      )}
    </div>
  )
}

export default Textarea
