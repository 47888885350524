import React from "react"

const ChatBubbleIcon = ({ style, title, className, ...restProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ display: "block", ...style }}
      viewBox="0 0 96 96"
      fill="none"
      alt="chat bubble icon by Praveen Patchu from The Noun Project"
      {...restProps}
    >
      {title && <title>{title}</title>}
      <path
        d="M58.7 11H37.3C19.5 11 5 25.5 5 43.3C5 52 8.5 59.9 14.2 65.7C15.7 67.2 16.3 69.3 16 71.4L14.4 82.3C14.1 84.3 16.1 85.8 18 85L37.8 75.8C38.7 75.4 39.7 75.2 40.7 75.2H59.3C76.8 75.1 91 60.8 91 43.3C91 25.5 76.5 11 58.7 11ZM27 48.6C23.4 48.6 20.5 45.7 20.5 42.1C20.5 38.5 23.4 35.6 27 35.6C30.6 35.6 33.5 38.5 33.5 42.1C33.5 45.7 30.6 48.6 27 48.6ZM48 48.6C44.4 48.6 41.5 45.7 41.5 42.1C41.5 38.5 44.4 35.6 48 35.6C51.6 35.6 54.5 38.5 54.5 42.1C54.5 45.7 51.6 48.6 48 48.6ZM69 48.6C65.4 48.6 62.5 45.7 62.5 42.1C62.5 38.5 65.4 35.6 69 35.6C72.6 35.6 75.5 38.5 75.5 42.1C75.5 45.7 72.6 48.6 69 48.6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChatBubbleIcon
