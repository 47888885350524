import React, { useEffect, useRef, useState } from "react"
import { produce } from "immer"
import { atom } from "jotai"

import { userAtom } from "../atoms/userAtom"
import { socketChatAtom, activeChatRoomIdAtom } from "../atoms/chatAtom"

export const messageAtom = atom("")
export const isFocusAtom = atom(false)

export const isTypingAtom = atom([])
export const startTypingAtom = atom(null, (_get, set, user) => {
  const me = _get(userAtom)
  const isTyping = _get(isTypingAtom)
  // console.log({ start: user })
  if (user?._id !== me?._id) {
    let index = isTyping?.findIndex((u) => u?._id === user?._id)
    if (index === -1) {
      let typing = produce(isTyping, (draft) => {
        draft.push(user)
      })
      set(isTypingAtom, typing)
    }
  }
})
export const stopTypingAtom = atom(null, (_get, set, user) => {
  const isTyping = _get(isTypingAtom)
  let index = isTyping?.findIndex((u) => u?._id === user?._id)
  // console.log({ stop: user })
  if (index !== -1) {
    let updatedTyping = produce(isTyping, (draft) => {
      draft.splice(index, 1)
    })
    set(isTypingAtom, updatedTyping)
  }
})

export const roomParticipantsAtom = atom([])
export const addRoomParticipantAtom = atom(null, (_get, set, user) => {
  let participants = _get(roomParticipantsAtom)
  let index = participants?.findIndex((u) => u?.id === user?.id)
  if (index === -1) {
    let updatedParticipants = produce(participants, (draft) => {
      draft.push(user)
    })
    set(roomParticipantsAtom, updatedParticipants)
  }
})
export const removeRoomParticipantAtom = atom(null, (_get, set, user) => {
  let participants = _get(roomParticipantsAtom)
  let index = participants?.findIndex((u) => u?.id === user?.id)
  if (index !== -1) {
    let updatedParticipants = produce(participants, (draft) => {
      draft.splice(index, 1)
    })
    set(roomParticipantsAtom, updatedParticipants)
  }
})
