import styles from "./ChatButton.module.scss"
import React from "react"
import cx from "clsx"

import ChatBubbleFilledIcon from "../../icons/ChatBubbleFilledIcon"

import IconButton from "../../IconButton/IconButton"
import ChatBubbleIcon from "../../icons/ChatBubbleIcon"
import { unreadChatsAtom } from "../../../atoms/chatAtom"
import { useAtomValue } from "jotai"

const ChatBadge = () => {
  const unreadChats = useAtomValue(unreadChatsAtom)

  return unreadChats?.length > 0 ? (
    <span className={styles.unread}>{unreadChats?.length}</span>
  ) : null
}

const ChatButton = ({ children, className, ...restProps }) => {
  return (
    <IconButton badge={2} className={cx(styles.root)} {...restProps}>
      <ChatBubbleIcon className={styles.icon} />
    </IconButton>
  )
}

export default ChatButton
