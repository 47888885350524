import styles from "./Item.module.scss"
import React, { useEffect, useState } from "react"
import cx from "clsx"

import { event } from "../../../utils/event"

import Text from "../../Text/Text"
import Link from "../../Link/Link"
import { formatDistanceToNowStrict, roundToNearestMinutes } from "date-fns"
import { id } from "date-fns/locale"
import AvatarAlt from "../../AvatarAlt/AvatarAlt"
import { useSetAtom } from "jotai"
import { readNotificationAtom } from "../../../atoms/notificationAtom"
import ResumeVerifiedIcon from "../../icons/ResumeVerifiedIcon"
import ResumeRejectedIcon from "../../icons/ResumeRejectedIcon"
import BriefcaseFilledIcon from "../../icons/BriefcaseFilledIcon"
import { useRouter } from "next/router"

export const useSameRoute = () => {
  const [sameRoute, setSameRoute] = useState(undefined)
  const router = useRouter()
  useEffect(() => {
    if (router?.isReady) {
      console.log({ router })
    }
  }, [router])
  return sameRoute
}

export const useReadNotification = (notification) => {
  const readNotification = useSetAtom(readNotificationAtom)
  const handleRead = async () => {
    await readNotification({ notificationId: notification?._id })
  }
  return handleRead
}

const Timestamp = ({ notification }) => {
  return (
    <Text as="p" caption className={styles.timestamp}>
      {formatDistanceToNowStrict(new Date(notification?.created_at), {
        addSuffix: true,
        roundingMethod: "floor",
        locale: id,
      })}
    </Text>
  )
}

const ResumeVerified = ({ notification }) => {
  const Wrapper = notification?.candidate ? Link : "div"
  const to = `/dashboard/resume`

  const handleRead = useReadNotification(notification)

  return (
    <li className={styles.root}>
      <Wrapper to={to} className={styles.wrapper} onClick={handleRead}>
        <div className={styles.iconWrapper}>
          <ResumeVerifiedIcon className={cx(styles.icon, styles.verified)} />
        </div>
        <div className={styles.main}>
          <Text
            small
            as="p"
            className={cx(styles.text, {
              [styles.unread]: !notification?.isRead,
            })}
          >
            <strong>Resume berhasil diverifikasi!</strong>
          </Text>
          <Timestamp notification={notification} />
        </div>
        <aside className={styles.aside}>
          {!notification?.isRead && notification?.candidate && (
            <div className={styles.marker} />
          )}
        </aside>
      </Wrapper>
    </li>
  )
}

const ResumeRejected = ({ notification }) => {
  const Wrapper = notification?.candidate ? Link : "div"
  const to = `/dashboard/resume`

  const handleRead = useReadNotification(notification)

  return (
    <li className={styles.root}>
      <Wrapper to={to} className={styles.wrapper} onClick={handleRead}>
        <div className={styles.iconWrapper}>
          <ResumeRejectedIcon className={cx(styles.icon, styles.rejected)} />
        </div>
        <div className={styles.main}>
          <Text
            small
            as="p"
            className={cx(styles.text, {
              [styles.unread]: !notification?.isRead,
            })}
          >
            <strong>Verifikasi resume gagal!</strong> Perbarui data resume kamu
            dan silakan coba lagi.
          </Text>
          <Timestamp notification={notification} />
        </div>
        <aside className={styles.aside}>
          {!notification?.isRead && notification?.candidate && (
            <div className={styles.marker} />
          )}
        </aside>
      </Wrapper>
    </li>
  )
}

const VacancyPublished = ({ notification }) => {
  const Wrapper = notification?.vacancy ? Link : "div"
  const to = `/lowongan?job=${notification?.vacancy}&type=ssw&dialog=true`

  const handleRead = useReadNotification(notification)

  return (
    <li className={styles.root}>
      <Wrapper to={to} className={styles.wrapper} onClick={handleRead}>
        <div className={styles.iconWrapper}>
          <BriefcaseFilledIcon className={cx(styles.icon, styles.new)} />
        </div>
        <div className={styles.main}>
          <Text
            small
            as="p"
            className={cx(styles.text, {
              [styles.unread]: !notification?.isRead,
            })}
          >
            <strong>Lowongan Baru Dirilis</strong> {notification?.message}
          </Text>
          <Timestamp notification={notification} />
        </div>
        <aside className={styles.aside}>
          {!notification?.isRead && notification?.vacancy && (
            <div className={styles.marker} />
          )}
        </aside>
      </Wrapper>
    </li>
  )
}

const Item = ({ notification, className, ...restProps }) => {
  switch (notification.category) {
    case event.resume.verified:
      return <ResumeVerified notification={notification} />
    case event.resume.rejected:
      return <ResumeRejected notification={notification} />
    case event.job.published:
      return <VacancyPublished notification={notification} />

    default:
      return <p>Default</p>
  }
}

export default Item
