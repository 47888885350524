import styles from "./Area.module.scss"
import React, { useEffect, useRef } from "react"
import cx from "clsx"
import { format } from "date-fns"
import { useAtom, useSetAtom } from "jotai"
import { useAtomValue, useUpdateAtom } from "jotai/utils"
import { useInView } from "react-intersection-observer"

import Textarea from "../Textarea/Textarea"
import Button from "../Button/Button"
import Text from "../Text/Text"
import AvatarAlt from "../AvatarAlt/AvatarAlt"

import {
  activeChatRoomIdAtom,
  chatRoomAtom,
  chatRoomParticipantsAtom,
  useChatsQuery,
  isTypingAtom,
  removeUnreadChatAtom,
  sendChatAtom,
  unreadChatsAtom,
} from "../../atoms/chatAtom"
import { userAtom } from "../../atoms/userAtom"
import { documentFocusAtom } from "../../atoms/setupAtom"

import { isFocusAtom, messageAtom } from "../../hooks/useChat"

import { formatRole } from "../../utils/formatter"
import SendFilledIcon from "../icons/SendFilledIcon"

const ChatBox = React.forwardRef(
  ({ message, right, sameChat, unreadChats }, forwardedRef) => {
    const isFocus = useAtomValue(documentFocusAtom)
    const { ref, inView } = useInView({ threshold: 0 })

    const removeUnreadChat = useSetAtom(removeUnreadChatAtom)

    useEffect(() => {
      if (inView && message && isFocus && unreadChats?.length > 0) {
        removeUnreadChat({
          roomId: message?.roomId,
          chatId: message?._id,
        })
      }
    }, [inView, message, isFocus, unreadChats])

    return (
      <li
        ref={ref}
        className={cx(styles.chat, {
          [styles.right]: right,
          [styles.sameChat]: sameChat,
        })}
      >
        {!right ? (
          !sameChat ? (
            <picture
              className={cx(styles.photoWrapper, {
                [styles.right]: right,
                [styles.left]: !right,
              })}
            >
              <img
                className={styles.photo}
                width="48px"
                height="48px"
                src={message?.sender?.photo ?? "/default.png"}
                alt={message?.sender?.name}
              />
            </picture>
          ) : (
            <div
              className={cx(styles.dummyPhotoWrapper, {
                [styles.right]: right,
                [styles.left]: !right,
              })}
            ></div>
          )
        ) : null}
        <div
          ref={forwardedRef}
          className={cx(styles.chatBox, {
            [styles.right]: right,
            [styles.sameChat]: sameChat,
          })}
        >
          {!sameChat && !right && (
            <div className={styles.chatHeader}>
              <Text
                heading6
                as="p"
                className={cx(styles.username, {
                  [styles[message.sender.type]]: message?.sender?.type,
                })}
              >
                {message?.sender?.name}
                <span className={cx(styles.role, { [styles.right]: right })}>
                  – {formatRole(message?.sender?.role)}
                </span>
              </Text>
            </div>
          )}
          <Text small as="p" className={styles.message} prewrap>
            {message?.body}
          </Text>
          {message?.created_at && (
            <Text
              caption
              as="p"
              className={styles.caption}
              align="right"
              secondary={!right}
              whiteSecondary={right}
            >
              {format(new Date(message?.created_at), "HH:mm")}
            </Text>
          )}
          {message?.createdAt && (
            <Text
              caption
              as="p"
              className={styles.caption}
              align="right"
              secondary={!right}
              whiteSecondary={right}
            >
              {format(new Date(message?.createdAt), "hh:mm")}
            </Text>
          )}
        </div>
      </li>
    )
  }
)

const NoticeBox = React.forwardRef(({ notice }, forwardedRef) => {
  return (
    <li ref={forwardedRef} className={styles.notice}>
      {notice.body}
    </li>
  )
})

const TypingStatus = ({ roomId }) => {
  const isTyping = useAtomValue(isTypingAtom)
  if (isTyping?.[roomId]?.length === 1) {
    return (
      <span className={styles.typing}>
        {isTyping?.[roomId]?.[0]?.name} is typing...
      </span>
    )
  }

  if (isTyping?.[roomId]?.length > 1) {
    return <span className={styles.typing}>Several people are typing...</span>
  }

  return null
}

const InputArea = () => {
  const activeRoomId = useAtomValue(activeChatRoomIdAtom)
  const [message, setMessage] = useAtom(messageAtom)
  const setFieldFocus = useUpdateAtom(isFocusAtom)
  const sendChat = useUpdateAtom(sendChatAtom)

  const sendMessage = () => {
    if (message) {
      sendChat({ body: message, onSuccess: () => setMessage("") })
    }
  }

  useEffect(() => {
    return () => {
      setMessage("")
    }
  }, [activeRoomId])

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footer}>
        <TypingStatus roomId={activeRoomId} />
        <Textarea
          minRows={1}
          placeholder="Write your message..."
          id="chat-message-box"
          name="chat-message-box"
          // className={styles.chatBox}
          value={message}
          setValue={(e) => setMessage(e.target.value)}
          className={styles.box}
          onFocus={() => setFieldFocus(true)}
          onBlur={() => setFieldFocus(false)}
        />
        <button className={styles.cta} onClick={sendMessage}>
          <SendFilledIcon className={styles.icon} />
        </button>
      </div>
    </footer>
  )
}

const ChatPage = ({ page, unreadChats }) => {
  const me = useAtomValue(userAtom)
  const { fetchNextPage } = useChatsQuery()

  const { ref, inView } = useInView({ initialInView: true, triggerOnce: true })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  return page?.data?.map((msg, index) => {
    if (msg.category === "text") {
      return (
        <ChatBox
          ref={index === 0 ? ref : null}
          key={msg.created_at}
          message={msg}
          sameChat={msg?.sender?._id === page?.data?.[index + 1]?.sender?._id}
          right={msg?.sender?._id === me?._id}
          unreadChats={unreadChats}
        />
      )
    } else {
      return (
        <NoticeBox
          ref={index === 0 ? ref : null}
          key={`noticebox-${index}`}
          notice={msg}
        />
      )
    }
  })
}

const MessagesArea = () => {
  const { data: infiniteChats } = useChatsQuery()
  const unreadChats = useAtomValue(unreadChatsAtom)

  const chatAreaRef = useRef(null)
  function scrollToBottom() {
    if (
      chatAreaRef?.current?.scrollTop >= -128 &&
      chatAreaRef?.current?.scrollTop <= 0
    ) {
      chatAreaRef.current.scrollTop = chatAreaRef?.current?.scrollHeight
    }
  }

  // function onScroll() {
  //   console.log({
  //     scrollTop: chatAreaRef?.current?.scrollTop,
  //     scrollHeight: chatAreaRef?.current?.scrollHeight,
  //   })
  // }

  // useEffect(() => {
  //   if (chatAreaRef?.current) {
  //     chatAreaRef?.current?.addEventListener("scroll", onScroll)
  //   }
  //   return () => {
  //     chatAreaRef?.current?.removeEventListener("scroll", onScroll)
  //   }
  // }, [])

  useEffect(() => {
    if (unreadChats?.length > 0) scrollToBottom()
  }, [unreadChats])

  return (
    <ul ref={chatAreaRef} className={styles.area}>
      {infiniteChats?.pages?.map((page, pageIndex) => (
        <ChatPage
          key={`chat-page-${pageIndex}`}
          page={page}
          unreadChats={unreadChats}
        />
      ))}
    </ul>
  )
}

const Participant = ({ src, name }) => {
  return (
    <picture className={styles.participantPhotoWrapper} title={name}>
      <img
        src={src}
        width="24px"
        height="24px"
        className={styles.participantPhoto}
        alt={name}
      />
    </picture>
  )
}

const RoomHeader = () => {
  const room = useAtomValue(chatRoomAtom)

  return (
    <div className={styles.roomInfo}>
      <AvatarAlt
        src={room?.candidate?.photo}
        small
        square
        alt={room?.candidate?.name}
        contain
      />
      <div className={styles.roomText}>
        {room?.candidate?.name && (
          <Text heading4 as="h3">
            {room?.candidate?.name}
          </Text>
        )}
        {room?.roomType && (
          <Text caption secondary as="p">
            ({room?.roomType})
          </Text>
        )}
      </div>
    </div>
  )
}

const ChatHeader = () => {
  const participants = useAtomValue(chatRoomParticipantsAtom)

  return (
    <header className={styles.areaHeader}>
      <div>
        <RoomHeader />
      </div>
      <ul className={styles.participants}>
        {participants?.map((p) => (
          <Participant key={p?._id} src={p?.photo} name={p?.name} />
        ))}
      </ul>
    </header>
  )
}

const Area = React.memo(() => {
  return (
    <section className={styles.root}>
      {/* <div className={styles.areaWrapper}> */}
      {/* <ChatHeader /> */}
      <MessagesArea />
      <InputArea />
      {/* </div> */}
    </section>
  )
})

export default Area
