import styles from "./ChatPortal.module.scss"
import React, { useEffect } from "react"
import cx from "clsx"
import { useAtom, useSetAtom } from "jotai"
import { motion } from "framer-motion"
import Portal from "@reach/portal"

import Button from "../../Button/Button"

import { chatDialogAtom } from "../../../atoms/chatAtom"
import ChatList from "../../ChatList/ChatList"
import { contactFabAtom } from "../../../atoms/setupAtom"
import { useLockBodyScroll } from "../../../hooks"

const ChatPortal = ({ className, ...restProps }) => {
  useLockBodyScroll()
  const [showChatDialog, setShowChatDialog] = useAtom(chatDialogAtom)

  const setFabContact = useSetAtom(contactFabAtom)

  const handleOut = () => {
    setShowChatDialog(false)
  }

  useEffect(() => {
    if (showChatDialog) {
      setFabContact(false)
    } else {
      setFabContact(true)
    }
  }, [showChatDialog])

  return (
    <Portal>
      <motion.div
        className={cx(styles.root)}
        data-isOpen={showChatDialog}
        {...restProps}
      >
        <ChatList />
        <Button onClick={handleOut}>Close</Button>
      </motion.div>
    </Portal>
  )
}

export default ChatPortal
