import styles from "./IconButton.module.scss"
import React from "react"
import cx from "clsx"

const IconButton = ({
  children,
  badge,
  type,
  a11yLabel,
  primary,
  primaryAlt,
  secondary,
  secondaryAlt,
  transparentLight,
  transparentDark,
  small,
  large,
  className,
  ...restProps
}) => {
  return (
    <button
      type={type || "button"}
      aria-label={a11yLabel}
      className={cx({
        [styles.root]: true,
        [styles.badge]: badge,

        [styles.primary]: primary,
        [styles.primaryAlt]: primaryAlt,
        [styles.secondary]: secondary,
        [styles.secondaryAlt]: secondaryAlt,
        [styles.transparentLight]: transparentLight,
        [styles.transparentDark]: transparentDark,

        [styles.small]: small || !large,
        [styles.large]: large,
        [className]: className,
      })}
      {...restProps}
    >
      {children}
    </button>
  )
}

export default IconButton
