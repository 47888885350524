export const formatApplicationStatus = (status) => {
  switch (status) {
    case "0":
      return "Ditolak"
    case "1":
      return "Proses Seleksi"
    case "2":
      return "Menentukan Jadwal"
    case "3":
      return "Proses Interview"
    case "4":
      return "VISA"
    case "5":
      return "Menunggu Keberangkatan"
    case "6":
      return "Diberangkatkan"
    case "99":
      return "Dibatalkan"
  }
}

export const formatTitpStatus = (status) => {
  switch (status) {
    case "1":
      return "Alumni < 3 tahun"
    case "2":
      return "Sedang magang"
    case "3":
      return "Alumni 3 tahun atau lebih"
    default:
      break
  }
}

export const formatTitpDuration = (titp) => {
  return titp.status === "2"
    ? titp.start_month.label + " " + titp.start_year + " – sekarang"
    : titp.start_month.label +
        " " +
        titp.start_year +
        " – " +
        titp.end_month.label +
        " " +
        titp.end_year
}

export const formatVerificationStatus = (status) => {
  switch (status) {
    case 1:
      return "Requesting verification"
    case 2:
      return "Verified"
    default:
      return false
  }
}

export const formatButtonLabel = (status) => {
  switch (status) {
    case 0:
      return "Schedule Interview"
    case 1:
      return "Set to interview"
    case 2:
      return "Set to process VISA"
    case 3:
      return "Set to waiting for departure"
    default:
      return "Depart candidate"
  }
}

export const formatterDegree = (lv) => {
  switch (lv) {
    case "sd":
      return "SD/Sederajat"
    case "smp":
      return "SMP/Sederajat"
    case "sma":
      return "SMA/Sederajat"
    case "d1":
      return "Diploma satu (D1)"
    case "d2":
      return "Diploma dua (D2)"
    case "d3":
      return "Diploma tiga (D3)"
    case "d4":
      return "Diploma empat (D4)"
    case "s1":
      return "Sarjana (S1)"
    case "s2":
      return "Magister (S2)"
    case "s3":
      return "Doktor (S3)"
    default:
      break
  }
}

export const toIDR = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "JPY",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export const containLetterRegex = /[a-zA-Z]/g

export const formatRole = (role, company) => {
  switch (role) {
    case "admin":
      return "Staff Talent Indonesia"
    case "pic":
      return company
    case "candidate":
      return "Candidate"
    default:
      break
  }
}

export const formatHasBahasa = (bahasa) => {
  if (
    bahasa?.jlpt_certificate ||
    bahasa?.nat_certificate ||
    bahasa?.jft_certificate ||
    bahasa?.nurse_certificate ||
    bahasa?.other_certificate
  ) {
    return true
  } else {
    return false
  }
}
