import styles from "./AvatarArea.module.scss"
import React, { useState } from "react"
import cx from "clsx"
import { Inline, Box } from "tenka"

import NavbarMenu from "../NavbarMenu/NavbarMenu"
import AvatarNavbar from "../AvatarNavbar/AvatarNavbar"
import Button from "../Button/Button"
import Sheet from "../Sheet/Sheet"
import LoginForm from "../LoginForm/LoginForm"
import Spacer from "../../layouts/Spacer/Spacer"
import { useSession } from "next-auth/react"
import Bell from "../Notification/Bell/Bell"
import { useRouter } from "next/router"
import RegisterForm from "../RegisterForm/RegisterForm"
import ChatButton from "../Chat/ChatButton/ChatButton"
import { useAtom } from "jotai"
import { chatDialogAtom } from "../../atoms/chatAtom"
import ChatPortal from "../Chat/ChatPortal/ChatPortal"

export const Unauthenticated = ({ status, redirectUrl }) => {
  const [showLogin, setShowLogin] = React.useState(false)
  const [show, setShow] = React.useState("")

  if (status === "loading") {
    return (
      <Inline space="s">
        <Button smallAlt primaryGhost loading={true} disabled={true}>
          Login
        </Button>
        <Button small primary loading={true} disabled={true}>
          Daftar
        </Button>
      </Inline>
    )
  }

  return (
    <>
      <Inline space="s" justifyContent="end">
        {status === "unauthenticated" && (
          <>
            <Button
              smallAlt
              primaryGhost
              onClick={() => {
                setShowLogin(true)
                setShow("login")
              }}
              loading={status === "loading"}
              disabled={status === "loading"}
            >
              Login
            </Button>
            <Button
              small
              primary
              onClick={() => {
                setShowLogin(true)
                setShow("register")
              }}
              loading={status === "loading"}
              disabled={status === "loading"}
            >
              Daftar
            </Button>
          </>
        )}
        {status === "authenticated" && session?.user?._id && (
          <>
            <NavbarMenu to="/dashboard/home">Dashboard</NavbarMenu>
            <AvatarNavbar />
          </>
        )}
      </Inline>
      <Sheet
        show={showLogin}
        setShow={setShowLogin}
        ariaLabel={show === "login" ? "login popup" : "form pendaftaran"}
        title={show === "login" ? "Login" : "Daftar akun baru"}
      >
        {show === "login" && (
          <LoginForm
            key="sheet-login"
            redirectUrl={redirectUrl}
            switchForm={() => setShow("register")}
          />
        )}
        {show === "register" && (
          <RegisterForm
            key="sheet-register"
            redirectUrl={redirectUrl}
            switchForm={() => setShow("login")}
          />
        )}
        <Spacer space="xl" />
      </Sheet>
    </>
  )
}

const AvatarArea = () => {
  const router = useRouter()
  const { data: session, status } = useSession()

  const [showChat, setShowChat] = useAtom(chatDialogAtom)

  if (status === "authenticated" && session && session?.user?._id) {
    return (
      <>
        <Inline space="s" justifyContent="end">
          <div className="desktop">
            {router?.pathname === "/" && (
              <NavbarMenu to="/dashboard/home">Dashboard</NavbarMenu>
            )}
            {(router?.pathname === "/dashboard/home" ||
              router?.pathname === "/dashboard/resume") && (
              <NavbarMenu to="/dashboard/resume">Resume</NavbarMenu>
            )}
          </div>
          <Inline space="s" align="center">
            {session && (
              <>
                {/* <div className={styles.buttonParent}> */}
                {/* <ChatButton onClick={() => router.push("/dashboard/chat")} /> */}
                {/* <ChatPortal /> */}
                {/* </div> */}
                <Bell />
              </>
            )}
            <AvatarNavbar />
          </Inline>
        </Inline>
      </>
    )
  }

  return <Unauthenticated status={status} />
}

export default AvatarArea
