import styles from "./ChatList.module.scss"
import React, { useEffect, Suspense } from "react"
import cx from "clsx"
import { useAtomValue, useSetAtom } from "jotai"

import { userAtom } from "../../atoms/userAtom"

import {
  activeChatRoomIdAtom,
  enterChatRoomAtom,
  leaveChatRoomAtom,
} from "../../atoms/chatAtom"

import Text from "../Text/Text"
import Area from "../Area/Area"
import LoaderIcon from "../icons/LoaderIcon"

const WaitingConfirmationPage = () => {
  return (
    <div
      style={{
        display: "grid",
        placeContent: "center center",
        height: "calc(100vh - 64px)",
      }}
    >
      <Text heading4 as="p" align="center">
        Fitur chat anda sedang dalam pengaturan
      </Text>
      <br />
      <Text small as="p" align="center">
        Silakan tunggu beberapa saat
      </Text>
    </div>
  )
}

const SuspenseWrappedChatList = ({ ...restProps }) => {
  const user = useAtomValue(userAtom)
  const activeRoomId = useAtomValue(activeChatRoomIdAtom)

  const enterChatRoom = useSetAtom(enterChatRoomAtom)
  const leaveChatRoom = useSetAtom(leaveChatRoomAtom)

  useEffect(() => {
    if (activeRoomId && activeRoomId !== "loading" && user) {
      enterChatRoom({ roomId: activeRoomId, user })
    }

    return () => {
      if (activeRoomId && user) {
        leaveChatRoom({ roomId: activeRoomId, user: user })
      }
    }
  }, [activeRoomId, user])

  return (
    <div className={cx(styles.root)} {...restProps}>
      <div className={styles.chatArea}>
        {activeRoomId === "loading" && <LoadingChatArea />}
        {activeRoomId !== "loading" &&
          (activeRoomId ? <Area /> : <WaitingConfirmationPage />)}
      </div>
    </div>
  )
}

const LoadingChatArea = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "calc(100vh - 64px)",
        position: "relative",
        display: "grid",
        placeContent: "center",
      }}
    >
      <LoaderIcon style={{ width: 80, height: 80 }} />
    </div>
  )
}

const ChatList = ({ ...restProps }) => {
  return (
    <Suspense fallback={<LoadingChatArea />}>
      <SuspenseWrappedChatList {...restProps} />
    </Suspense>
  )
}

export default ChatList
